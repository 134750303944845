:root {
  --margin:
}

.app {
  position: relative;
  min-height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
}

img.logo {
  width: auto;
  height: 64px;
}

@media screen and (min-height: 600px) {
  .app {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  img.logo {
    height: 72px;
  }
}

@media screen and (min-height: 800px) {
  .app {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  img.logo {
    height: 96px;
  }
}

@media screen and (min-height: 1280px) {
  .app {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  img.logo {
    height: 128px;
  }
}

.app > footer {
  color: white;
  text-align: center;
  width: 640px;
  font-size: 16px;
}
