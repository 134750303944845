.button {
    font-size: 20px;
    font-weight: bold;
    background-color: green;
    border-left: none;
    border-right: none;
    border-top: 4px solid rgb(172, 221, 66);
    border-bottom: 4px solid black;
    color: white;
    padding: 0.5em 2em;
    transition: .1s;
}

.button:hover {
    background-color: darkgreen;
    border-top-color: green;
}

.button:active {
    transform: translateY(4px);
    border-bottom-color: transparent;
}

.button:disabled {
    opacity: 0.75;
    background-color: green;
    border-top-color: rgb(172, 221, 66);
    border-bottom-color: black;
    color: darkgreen;
    transform: none;
}

.button-link {
    font-size: 16px;
    color: rgb(45, 45, 45);
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
}
button.button-link {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
}