.form {
    margin: 0 auto;
    max-width: 480px;
    background-color: #ede5e2;
    color: rgb(49, 49, 49);
    border-top: 4px solid #d0c5c0;
    border-bottom: 4px solid black;
    padding-bottom: 16px;
}

.form > header {
    padding: 8px 0;
    font-size: 20px;
    background-color: #d0c5c0;
    font-weight: bold;
}
.form > header, .form > footer {
    text-align: center;
}

.form label {
    font-weight: bold;
}

.form .inputs {
    text-align: left;
}

.form .inputs, form > footer {
    padding: 8px 16px 16px 16px;
}

.form input[type=text],
.form input[type=password],
.form .input {
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    display: block;
    background-color: white;
    color: black;
    border: 2px solid rgb(171, 160, 156);
    padding: 8px 16px;
    margin-top: 4px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
}

.form .input {
    display: flex;
    justify-content: space-between;
}

.form input[type=text]:read-only,
.form input[type=password]:read-only,
.form .input {
    background-color: rgb(220, 208, 203);
}

.form input[type=text]:invalid,
.form input[type=password].invalid,
.form input.invalid {
    border-color: rgb(187, 68, 17);
}